import React from "react"
import styled from "styled-components"

const Video = styled.iframe`
  width: 100%;
  display: block;
  max-width: 900px;
  margin: 0 auto 2rem auto;
  border: none;
  border: 2px solid #fff;

  @media all and (min-width: 768px) {
    height: 510px;
    margin: 0 auto 3rem auto;
  }
`

const PromoVideoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;

  ${Video} {
    margin: 0;
  }

  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 3rem;
    grid-gap: 1rem;

    ${Video} {
      height: 315px;
    }
  }
`

const PromoVideos = ({ promoVideos, animate = true }) => {
  switch (promoVideos.length) {
    case 1:
      return promoVideos.map((video, idx) => (
        <Video
          key={idx}
          src={`https://www.youtube-nocookie.com/embed/${video}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen
        />
      ))
    case 2:
      return (
        <PromoVideoGrid>
          {promoVideos.map((video, idx) => (
            <Video
              key={idx}
              src={`https://www.youtube-nocookie.com/embed/${video}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowFullScreen
              data-wow-delay={`.${idx * 2}s`}
              {...(animate ? { className: "wow fadeIn" } : {})}
            />
          ))}
        </PromoVideoGrid>
      )
    case 3:
      const firstVideo = promoVideos.shift()
      return (
        <>
          <Video
            src={`https://www.youtube-nocookie.com/embed/${firstVideo}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen
            {...(animate ? { className: "wow fadeIn" } : {})}
          />
          <PromoVideoGrid>
            {promoVideos.map((video, idx) => (
              <Video
                key={idx}
                src={`https://www.youtube-nocookie.com/embed/${video}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
                {...(animate ? { className: "wow fadeIn" } : {})}
                data-wow-delay={`.${(idx + 1) * 2}s`}
              />
            ))}
          </PromoVideoGrid>
        </>
      )
    default:
      return null
  }
}

export default PromoVideos
