import { useStaticQuery, graphql } from "gatsby"

export const useShows = () => {
  const collection = useStaticQuery(
    graphql`
      query showsQuery {
        shows: allMdx(
          filter: {
            fileAbsolutePath: { regex: "/shows/" }
            frontmatter: { active: { eq: true } }
          }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                slug
                title
                htmlTitle
                cover {
                  src: childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                video {
                  publicURL
                }
                videoThumbnail {
                  publicURL
                }
                location
                stageSize
                stageType
                productionType
                showLength
                employment
                targetGroup
                priceRange
              }
            }
          }
        }
      }
    `
  )

  return collection.shows.edges.reduce(
    (acc, show) => [
      ...acc,
      {
        id: show.node.id,
        ...(show.node ? { ...show.node.frontmatter } : {}),
      },
    ],
    []
  )
}
