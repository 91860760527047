import React from "react"
import styled from "styled-components"
import { useSiteMeta } from "../hooks/siteMeta"

const Container = styled.div`
  text-align: right;
`
// const Image = styled.div`
//   max-width: 600px;
//   height: 250px;
//   background-color: transparent;
//   position: relative;
//   background-image: ${props => `url(${props.bg})`};
//   background-position: center;
//   background-size: cover;
//   clip-path: polygon(
//     0 0,
//     100% 0,
//     100% calc(100% - 10px),
//     calc(100% - 10px) 100%,
//     0 100%
//   );
//   transition: all 0.3s ease-in-out;

//   &:before {
//     position: absolute;
//     display: block;
//     content: "";
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     clip-path: polygon(
//       0 0,
//       100% 0,
//       100% calc(100% - 10px),
//       calc(100% - 10px) 100%,
//       0 100%,
//       0 2px,
//       2px 2px,
//       2px calc(100% - 2px),
//       calc(100% - 11px) calc(100% - 2px),
//       calc(100% - 2px) calc(100% - 11px),
//       calc(100% - 2px) 2px,
//       0 2px
//     );
//     background: #fff;
//     z-index: -1;
//     opacity: 1;
//   }
// `
const Title = styled.h3`
  font-size: 1.3333333em;
  margin-bottom: 0.5rem;
`
const StyledLink = styled.a`
  color: #fff;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  display: block;

  &:hover {
    color: #6c9fff;
  }

  & > video {
    @media all and (min-width: 768px) {
      height: 250px;
    }
  }
`
// const TitleLink = styled(StyledLink)`
//   display: inline-block;
// `
const Video = styled.video`
  height: 100%;
  object-fit: cover;
  width: 100%;
  border: 2px solid #fff;
`
const Show = React.forwardRef(({ title, cover, slug, video, ...rest }, ref) => {
  const { url } = useSiteMeta()
  return (
    <Container ref={ref} {...rest}>
      <StyledLink
        href={`${url}${slug}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Title dangerouslySetInnerHTML={{ __html: title }} />
      </StyledLink>
      <StyledLink
        href={`${url}${slug}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Video
          key={video}
          autoPlay
          muted
          loop
          playsInline
          poster={cover}
          preload="metadata"
        >
          <source src={video} type="video/mp4" />
        </Video>
      </StyledLink>
    </Container>
  )
})

export default Show
