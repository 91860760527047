import React, { useEffect, useRef } from "react"
import styled from "styled-components"

import Container from "../Container"
import Show from "../Show"
import { useShows } from "../../hooks/shows"

const Section = styled.section`
  background-color: #1a1a1a;
  padding: 5rem 0;
`
const Title = styled.h2``
const ShowGrid = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr;

  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const buildCovesSrc = collection =>
  collection.reduce(
    (acc, show) => [
      ...acc,
      {
        ...show,
        cover: show?.cover?.src.fluid.src,
        ...(show.videoThumbnail
          ? { video: show.videoThumbnail.publicURL }
          : {}),
      },
    ],
    []
  )

const Shows = ({ title, shows }) => {
  const allShow = useShows()
  const shouldRender = shows || buildCovesSrc(allShow)
  const showsRef = useRef([])

  useEffect(() => {
    showsRef.current = showsRef.current.slice(0, shouldRender.length)
    return showsRef?.current?.forEach((ref, idx) =>
      idx % 2 === 1
        ? ref.classList.add("show-magic-with-delay")
        : ref.classList.add("show-magic")
    )
  }, [shouldRender])
  return (
    <Section>
      <Container>
        {title && title}
        <ShowGrid>
          {shouldRender.map((show, idx) => (
            <Show
              key={idx}
              ref={show => (showsRef.current[idx] = show)}
              className="wow"
              data-wow-delay={idx % 2 ? ".2s" : ""}
              {...show}
            />
          ))}
        </ShowGrid>
      </Container>
    </Section>
  )
}

export default Shows
