/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Header from "../header"
import Footer from "./v2/Footer"
import Partners from "../sections/Partners"
import { useAboutAssets } from "../../hooks/about"
import SEO from "../seo"
import CTA from "../sections/CTA"
import Container from "../Container"
import Shows from "../sections/Shows"
import VideoHero from "../sections/VideoHero"
import { useSiteMeta } from "../../hooks/siteMeta"
import Button from "../Button"
import CookieConsent from "../CookieConsent"
import Up from "../Up"
import PromoVideos from "../PromoVideos"
import Gallery from "../Gallery"

const Main = styled.main`
  background-color: #1a1a1a;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 70px 0 100px 0;

  @media all and (min-width: 769px) {
    background-image: ${props => `url(${props.bg})`};
  }
`
const AltTitleContainer = styled.div`
  text-align: center;
  max-width: 900px;
  margin: 0 auto 3rem auto;
  padding: 0 15px;
`
const ContentContainer = styled.div`
  max-width: 960px;
  margin: 0 auto 3rem auto;
  padding: 0 15px;

  @media all and (min-width: 768px) {
    margin: 0 auto 5rem auto;
  }
`
const Article = styled.article`
  text-align: left;
  .gatsby-resp-image-wrapper {
    margin: 30px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: #6c9fff;
  }

  a {
    text-decoration: none;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  @media all and (min-width: 768px) {
    text-align: center;
  }
`
const Tag = styled.span`
  font-size: 1.5em;
  color: #fff;
  text-transform: uppercase;
  background-color: #1b2840;
  padding: 5px 15px 0 15px;
  display: inline-block;
  letter-spacing: 2px;
  font-family: "Teko", sans-serif;
  line-height: 0.9;
`
const Image = styled.div`
  max-width: 600px;
  height: 250px;
  background-color: transparent;
  position: relative;
  background-image: ${props => `url(${props.bg})`};
  background-position: center;
  background-size: cover;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%
  );
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 2px,
      2px 2px,
      2px calc(100% - 2px),
      calc(100% - 11px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 11px),
      calc(100% - 2px) 2px,
      0 2px
    );
    z-index: -1;
    opacity: 1;
  }

  &:hover {
    transform: scale(1.03);
  }
  @media all and (min-width: 768px) {
    grid-column: span 2;
  }
`
const Accent = styled.span`
  color: #6c9fff;
`
const SectionTitle = styled.h2`
  font-size: 2.22222222em;
  text-align: center;
  margin-bottom: 2.7777778rem;
`
const MorePostContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5.5555556rem;
`
const MorePostButton = styled(Button)`
  font-size: 1.77777778em;
  padding: 15px 40px 10px 40px;
`

const ShowPageLayout = ({ data: { mdx, related } }) => {
  const { bg } = useAboutAssets()
  const { url } = useSiteMeta()

  return (
    <>
      <SEO
        title={mdx.frontmatter.seoTitle}
        description={mdx.frontmatter.seoMetaDescription}
        ogImage={mdx.frontmatter.cover.src.resize.src}
        slug={mdx.frontmatter.slug}
      />
      <CookieConsent />
      <Up />
      <Header videoBg />
      <VideoHero
        htmlTitle={mdx.frontmatter.htmlTitle}
        bg={mdx.frontmatter.cover.src.fluid.src}
        video={mdx.frontmatter.video?.publicURL || ""}
        target="#tartalom"
        homepage
      />
      <Main bg={bg} id="tartalom">
        <Article>
          <AltTitleContainer className="wow fadeIn">
            <Tag>Flipunit production</Tag>
          </AltTitleContainer>
          <ContentContainer className="wow fadeIn">
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </ContentContainer>
          <Container>
            <PromoVideos promoVideos={mdx.frontmatter.promoVideos} />
            <Gallery gallery={mdx.frontmatter.gallery} url={url} />
          </Container>
        </Article>
        <CTA />
        <Shows
          title={
            <SectionTitle>
              <Accent>Tekintse meg</Accent> hasonló show műsorainkat!
            </SectionTitle>
          }
          shows={related.edges.reduce(
            (acc, value) => [
              ...acc,
              {
                title: value.node.frontmatter.title,
                slug: value.node.frontmatter.slug,
                cover: value.node.frontmatter.cover.src.fluid.src,
                ...(value.node.frontmatter.videoThumbnail
                  ? { video: value.node.frontmatter.videoThumbnail.publicURL }
                  : {}),
              },
            ],
            []
          )}
        />
        <MorePostContainer>
          <MorePostButton className="wow fadeIn" to="/sportbemutatok">
            További műsorok
          </MorePostButton>
        </MorePostContainer>
        <Partners />
      </Main>
      <Footer color={"#6c9fff"} />
    </>
  )
}

export default ShowPageLayout

export const pageQuery = graphql`
  query ShowQuery($id: String, $related: [String]) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        seoTitle
        htmlTitle
        slug
        seoMetaDescription
        cover {
          src: childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              src
            }
            resize(width: 900, quality: 90) {
              src
            }
          }
        }
        gallery {
          image {
            lightbox: childImageSharp {
              fluid(maxWidth: 1200) {
                src
              }
            }
          }
          imageAlt
        }
        promoVideos
        video {
          publicURL
        }
      }
    }
    related: allMdx(
      filter: { frontmatter: { title: { in: $related }, active: { eq: true } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            cover {
              src: childImageSharp {
                fluid {
                  src
                }
              }
            }
            videoThumbnail {
              publicURL
            }
          }
        }
      }
    }
  }
`
